import React, { } from 'react'

interface IBlurBackgroundProps {
  dark?: boolean,
}
function BlurBackground({ dark, }: IBlurBackgroundProps) {
  return (
    <>
      <div style={{
          position: 'absolute', zIndex: -1, width: '100%', height: '100%',
          filter: 'blur(50px)',
          backgroundColor: dark ? 'rgba(0, 0, 0, 0.3)' : 'rgba(255, 255, 255, 0.7)',
        }}
      />
    </>
  )
}

export default BlurBackground
