import React, { useEffect, useState } from 'react'
import { View, DimensionValue, Linking, } from 'react-native'
import { useSelector } from 'react-redux'
import { IStores } from '../../state/store'
import Button from '../button'
import { appStoreListing, headlines, } from '../../utils/constants'
import ProgressBar from '../animated/ProgressBar'
import Text from '../text'
import { sendUtmMarketing } from '../../utils/services'
import BlurBackground from '../blurBackground'

interface IInfoBlockProps {
  order: number,
  mobile: boolean,
  masterRef: any,
}
function InfoBlock({ order, mobile, masterRef, }: IInfoBlockProps) {
  const { Colors, Fonts, Spacing, } = useSelector((state: IStores) => mobile ? state.systemStore.Mobile : state.systemStore.Desktop)
  const { School, } = useSelector((state: IStores) => state.systemStore)

  const [focused, setFocused] = useState<number | null>(null)
  const [referer, setReferer] = useState<string | null>(null)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const ref = params.get('ref')
    if (ref) setReferer(ref)
  }, [])

  return (
    <>
      <View style={{position: 'absolute', top: `${order}00%` as DimensionValue, height: '100%', width: '100%', backgroundColor: '#191970',}}>
        <View style={{flex: 8, flexDirection: mobile ? 'column' : 'row', paddingTop: 16, paddingHorizontal: Spacing.paddingMd,}}>
          <View style={{flex: 2, marginRight: mobile ? 0 : 32, justifyContent: 'center',}}>
            <View style={{height: 32,}} />

            <Text style={{fontSize: Fonts.xxl, fontWeight: Fonts.heavyWeight, marginBottom: 8, color: Colors.white}}>How does it work?</Text>
            <Text style={{fontSize: Fonts.lg, fontWeight: Fonts.middleWeight, color: Colors.white,}}>Streetpass shows you all the {School.name ? 'other students' : 'people'} you pass{School.name ? ` by on ${School.name.toUpperCase()} campus` : ` in the street`} throughout your day. It gives you the opportunity to match with missed connections, friends of friends, and people of similar interests who go to all the same places you do.</Text>

            <View style={{marginVertical: 24,}}>
              <Button
                title={'Get the app'}
                onPress={async () => {
                  // if (masterRef.current) masterRef.current.scrollTop = 0
                  if (referer) await sendUtmMarketing(referer)
                  Linking.openURL(appStoreListing)
                }}
              />
            </View>
          </View>

          <View style={{flex: 3, flexDirection: 'row', justifyContent: 'center', paddingVertical: 32,}}>
            <View
              onPointerEnter={() => setFocused(0)}
              // onPointerLeave={() => setFocused(null)}
              style={{flex: focused === 0 ? (mobile ? 3 : 2) : 1, paddingHorizontal: 4,}}
            >
              <img
                src={require('../../assets/media/screenshot-1.png')}
                alt={''}
                style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 16, overflow: 'hidden', backgroundColor: Colors.white,}}
              />
            </View>

            <View
              onPointerEnter={() => setFocused(1)}
              // onPointerLeave={() => setFocused(null)}
              style={{flex: focused === 1 ? (mobile ? 3 : 2) : 1, paddingHorizontal: 4,}}
            >
              <img
                src={require('../../assets/media/screenshot-2.png')}
                alt={''}
                style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 16, overflow: 'hidden', backgroundColor: Colors.white,}}
              />
            </View>

            <View
              onPointerEnter={() => setFocused(3)}
              // onPointerLeave={() => setFocused(null)}
              style={{flex: focused === 3 ? (mobile ? 3 : 2) : 1, paddingHorizontal: 4,}}
            >
              <img
                src={require('../../assets/media/screenshot-3.png')}
                alt={''}
                style={{width: '100%', height: '100%', objectFit: 'cover', borderRadius: 16, overflow: 'hidden', backgroundColor: Colors.white,}}
              />
            </View>
          </View>

        </View>
      </View>
    </>
  )
}

export default InfoBlock
