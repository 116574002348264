import React, { useEffect, useRef, useState, } from 'react'
import { View, DimensionValue, Image, TouchableOpacity, Dimensions, Linking, } from 'react-native'
import { useSelector } from 'react-redux'
import { IStores } from '../../state/store'
import { thumbnail, video, gif, appStoreListing, } from '../../utils/constants'
import Text from '../text'
// import { ReactComponent as ChevronDownIcon, } from '../../assets/icons/chevron-down-filled.svg'
import BlurBackground from '../blurBackground'
import { ReactComponent as StreetpassColorIcon, } from '../../assets/images/logo-color.svg'
import { ReactComponent as StreetpassTransparentIcon, } from '../../assets/images/logo-transparent-white.svg'
import { ReactComponent as QrIcon, } from '../../assets/images/qr-transparent-rounded-white.svg'
import { useNavigate } from 'react-router-dom'
import { Screens } from '../../App'
import { sendUtmMarketing } from '../../utils/services'

interface IVideoBlockProps {
  order: number,
  mobile: boolean,
  masterRef: any,
}
function VideoBlock({ order, mobile, masterRef, }: IVideoBlockProps) {
  const { Colors, Fonts, Spacing, } = useSelector((state: IStores) => mobile ? state.systemStore.Mobile : state.systemStore.Desktop)
  const { School, } = useSelector((state: IStores) => state.systemStore)

  const [referer, setReferer] = useState<string | null>(null)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const ref = params.get('ref')
    if (ref) setReferer(ref)
  }, [])

  const navigate = useNavigate()

  const videoRef = useRef<HTMLVideoElement>(null)
  if (videoRef.current) {
    videoRef.current.defaultMuted = true
    videoRef.current.muted = true
    videoRef.current.playsInline = true
    videoRef.current.autoplay = true
    videoRef.current.loop = true
    videoRef.current.preload = 'auto'
  }

  // useEffect(() => {
  //   if (videoRef.current) videoRef.current.play().catch((error) => console.log('Autoplay error', error))
  //   // else setTimeout(() => videoRef.current?.play().catch((error) => console.log('Autoplay error', error)), 2000)
  // }, [])

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = true
      videoRef.current.playsInline = true
      videoRef.current.autoplay = true
      videoRef.current.loop = true
      videoRef.current.preload = 'auto'
    }

    const removeEventListeners = () => {
      document.removeEventListener('touchstart', tryPlayVideo)
      document.removeEventListener('scroll', tryPlayVideo)
      document.removeEventListener('click', tryPlayVideo)
      document.removeEventListener('keypress', tryPlayVideo)
      document.removeEventListener('mousemove', tryPlayVideo)
      window.removeEventListener('focus', tryPlayVideo)
      window.removeEventListener('load', tryPlayVideo)
    }

    const tryPlayVideo = () => {
      if (videoRef.current && videoRef.current.readyState >= 3) {
        videoRef.current.play().then(() => {}).catch((error) => console.log('Autoplay error', error))
        removeEventListeners()
      }
    }

    tryPlayVideo()

    document.addEventListener('touchstart', tryPlayVideo)
    document.addEventListener('scroll', tryPlayVideo)
    document.addEventListener('click', tryPlayVideo)
    document.addEventListener('keypress', tryPlayVideo)
    document.addEventListener('mousemove', tryPlayVideo)
    window.addEventListener('focus', tryPlayVideo)
    window.addEventListener('load', tryPlayVideo)
    return () => removeEventListeners()
  }, [])

  return (
    <>
      <View style={{position: 'absolute', top: `${order}00%` as DimensionValue, width: '100%', height: '100%',}}>        
        <View style={{height: Spacing.navHeight, flexDirection: 'row', justifyContent: 'space-between', overflow: 'hidden', backgroundColor: 'rgba(23,25,30, 1)', paddingHorizontal: Spacing.paddingMd,}}>
          <TouchableOpacity style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center',}}>
            <StreetpassColorIcon style={{width: Fonts.xxl, height: Fonts.xxl,}} />

            <Text style={{fontFamily: 'Fx', fontSize: Fonts.xl, fontWeight: Fonts.heavyWeight, color: Colors.white, textAlign: 'center',}}>
              Streetpass
            </Text>

            {School.logo &&
              <>
                <View style={{
                  aspectRatio: 1/1, borderRadius: Fonts.xxxxl, justifyContent: 'center', overflow: 'hidden',
                  width: Fonts.xxl, height: Fonts.xxl, backgroundColor: School.highlight, marginLeft: 8,
                }}>
                  <Image
                    source={School.logo}
                    style={{width: '100%', height: '100%',}}
                    resizeMode={'contain'}
                  />
                </View>
              </>
            }
          </TouchableOpacity>

          <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center',}}>
            {/* <TouchableOpacity
              onPress={() => {}}
              style={{
                flexDirection: 'row', paddingHorizontal: 16, paddingVertical: 4, borderRadius: 16,
                backgroundColor: (School.highlight || Colors.lightBlue), marginRight: 16,
              }}
            >
              <Text
                style={{fontSize: Fonts.lg, fontWeight: Fonts.welterWeight, color: Colors.white,}}
                props={{numberOfLines: 1,}}
              >Download for iPhone</Text>
            </TouchableOpacity> */}

            <TouchableOpacity
              onPress={() => navigate(Screens.About)}
              style={{justifyContent: 'center', alignItems: 'center', marginRight: 16,}}
            >
              <Text style={{fontFamily: 'Fx', fontSize: Fonts.md, fontWeight: Fonts.middleWeight, color: Colors.white, textAlign: 'center',}}>
                About
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => navigate(Screens.Help)}
              style={{justifyContent: 'center', alignItems: 'center',}}
            >
              <Text style={{fontFamily: 'Fx', fontSize: Fonts.md, fontWeight: Fonts.middleWeight, color: Colors.white, textAlign: 'center',}}>
                Help
              </Text>
            </TouchableOpacity>
          </View>

        </View>

        <View style={{flex: 9, overflow: 'hidden',}}>
          <img
            src={thumbnail}
            alt={''}
            style={{position: 'absolute', zIndex: -2, width: '100%', height: '100%', objectFit: 'cover', backgroundColor: Colors.white,}}
          />
          {mobile ? <img
              src={gif}
              alt={''}
              style={{position: 'absolute', zIndex: -1, width: '100%', height: '100%', objectFit: 'cover',}}
            />
          : <video
              ref={videoRef}
              playsInline
              autoPlay
              muted
              loop
              preload={'auto'}
              style={{position: 'absolute', zIndex: -1, width: '100%', height: '100%', objectFit: 'cover',}}
            >
              <source
                src={video}
                type={'video/mp4'}
              />
            </video>
          }

          <View style={{position: 'absolute', zIndex: 1, width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center',}}>
            <View style={{justifyContent: 'center', alignItems: 'center',}}>
              <Text
                style={{fontSize: Fonts.xxl, fontWeight: Fonts.heavyWeight, color: Colors.white, marginBottom: 32,}}
                props={{numberOfLines: 1,}}
              >For dating & friends.</Text>

              <View style={{marginBottom: 32,}}>
                <View style={{position: 'absolute', zIndex: -1, width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center',}}>
                  <BlurBackground dark={true} />
                  <StreetpassTransparentIcon style={{width: 50, height: 50, borderRadius: 20,}} />
                </View>
                <QrIcon style={{width: 212, height: 212,}} />
              </View>

              <TouchableOpacity
                onPress={async () => {
                  if (referer) await sendUtmMarketing(referer)
                  Linking.openURL(appStoreListing)
                }}
                style={{
                  flexDirection: 'row', paddingHorizontal: 16, paddingVertical: 4, borderRadius: 16,
                  backgroundColor: (School.highlight || Colors.lightBlue),
                }}
              >
                <Text
                  style={{fontSize: Fonts.lg, fontWeight: Fonts.welterWeight, color: Colors.white,}}
                  props={{numberOfLines: 1,}}
                >Download for iPhone</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>

      {/* <View style={{position: 'absolute', top: `${order}00%` as DimensionValue, width: '100%', height: '100%',}}>
        <img
          src={thumbnail}
          alt={''}
          style={{position: 'absolute', zIndex: -2, width: '100%', height: '100%', objectFit: 'cover', backgroundColor: Colors.white,}}
        />
        {mobile ? <img
            src={gif}
            alt={''}
            style={{position: 'absolute', zIndex: -1, width: '100%', height: '100%', objectFit: 'cover',}}
          />
        : <video
            ref={videoRef}
            playsInline
            autoPlay
            muted
            loop
            preload={'auto'}
            style={{position: 'absolute', zIndex: -1, width: '100%', height: '100%', objectFit: 'cover',}}
          >
            <source
              src={video}
              type={'video/mp4'}
            />
          </video>
        }

        <View style={{position: 'absolute', width: '100%', height: '15%', justifyContent: 'center', alignItems: 'center', marginTop: 32,}}>
          <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center',}}>
            <Text style={{fontFamily: 'Fx', fontSize: Fonts.xxl, fontWeight: Fonts.heavyWeight, color: Colors.white, textAlign: 'center',}}>
              Streetpass
            </Text>

            {School.logo &&
              <View style={{aspectRatio: 1/1, borderRadius: 64, justifyContent: 'center', left: 16, bottom: 2, backgroundColor: School.highlight, overflow: 'hidden',}}>
                <Image
                  source={School.logo}
                  style={{width: Fonts.xxxl, height: '130%',}}
                  resizeMode={'contain'}
                />
              </View>
            }
          </View>

          {School.name ?
            <Text style={{fontFamily: 'Fx', fontSize: Fonts.lg, fontWeight: Fonts.middleWeight, color: Colors.white, textAlign: 'center',}}>
              The dating app for <Text style={{color: School.highlight,}}>{School.name.toUpperCase()}</Text> students.
            </Text>
          : <Text style={{fontFamily: 'Fx', fontSize: Fonts.lg, fontWeight: Fonts.middleWeight, color: Colors.white, textAlign: 'right',}}>
              For dating & friends.
            </Text>
          }
        </View>

        <TouchableOpacity
          onPress={() => masterRef.current.scrollTop = Dimensions.get('window').height > 600 ? Dimensions.get('window').height : 600}
          style={{position: 'absolute', alignSelf: 'center', bottom: 144,}}
        >
          <ChevronDownIcon style={{width: mobile ? Fonts.xxxxl : Fonts.xxxl, height: mobile ? Fonts.xxxxl : Fonts.xxxl, fill: Colors.white,}} />
        </TouchableOpacity>
      </View> */}
    </>
  )
}

export default VideoBlock
