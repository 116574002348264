import React from 'react'
import { View, DimensionValue, Image, } from 'react-native'
import { useSelector } from 'react-redux'
import { IStores } from '../../state/store'
import Button from '../button'
import { email } from '../../utils/constants'
import Text from '../text'
import { ReactComponent as StreetpassColorIcon, } from '../../assets/images/logo-color.svg'

interface IAboutBlockProps {
  order: number,
  mobile: boolean,
}
function AboutBlock({ order, mobile, }: IAboutBlockProps) {
  const { Colors, Fonts, Spacing, } = useSelector((state: IStores) => mobile ? state.systemStore.Mobile : state.systemStore.Desktop)
  const { School, } = useSelector((state: IStores) => state.systemStore)

  return (
    <>
      <View style={{position: 'absolute', top: `${order}00%` as DimensionValue, height: '100%', width: '100%', padding: Spacing.padding, backgroundColor: 'rgba(23,25,30, 1)',}}>
        <View style={{flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 16,}}>
          <StreetpassColorIcon style={{width: Fonts.xxxxl, height: Fonts.xxxxl,}} />

          {School.logo &&
            <>
              <Text style={{color: Colors.white, fontSize: Fonts.xxl, marginRight: mobile ? 8 : 16,}}>𝑿</Text>

              <View style={{
                aspectRatio: 1/1, borderRadius: Fonts.xxxxl, justifyContent: 'center', overflow: 'hidden',
                width: Fonts.xxxl, height: Fonts.xxxl, backgroundColor: School.highlight, 
              }}>
                <Image
                  source={School.logo}
                  style={{width: '100%', height: '100%',}}
                  resizeMode={'contain'}
                />
              </View>
            </>
          }
        </View>

        <View style={{flex: 5, flexDirection: 'row', justifyContent: 'center',}}>
          <Text style={{fontSize: Fonts.lg, fontWeight: Fonts.welterWeight, color: Colors.white,}}>
Streetpass works by showing you the people you pass by throughout your day. Live your life and let Streetpass do it's thing in the background.
{`\n\n`}Working out at the gym, studying at your local café, attending a live music event - Streetpass finds the perfect moments to connect you with compatible matches wherever you go.
{`\n\n`}Be yourself, be safe and happy streetpassing!
{`\n\n`}- Streetpass team
          </Text>
        </View>
      </View>
    </>
  )
}

export default AboutBlock
