import React, { useCallback, useEffect, useRef, } from 'react'
import { View, Animated, Easing, } from 'react-native'
import { IStores } from '../../state/store'
import { useSelector } from 'react-redux'

interface IProgressBarProps {
  duration: number,
  reset: boolean,
}
function ProgressBar({ duration, reset, }: IProgressBarProps) {
  const { Colors, } = useSelector((state: IStores) => state.systemStore.Desktop)
  const { School, } = useSelector((state: IStores) => state.systemStore)

  const animatedWidth = useRef(new Animated.Value(0)).current

  const startAnimation = useCallback(() => {
    animatedWidth.setValue(0)
    const animation = Animated.timing(animatedWidth, {
      toValue: 1,
      duration: duration,
      useNativeDriver: false,
      easing: Easing.inOut(Easing.ease),
    })
    animation.start()
  }, [duration, animatedWidth])

  useEffect(() => {
    startAnimation();
  }, [duration, reset, startAnimation])

  return (
    <View style={{width: '100%', height: 10, backgroundColor: 'transparent', overflow: 'hidden',}}>
      <Animated.View
        style={{
          width: animatedWidth.interpolate({ inputRange: [0, 1], outputRange: ['0%', '100%'], }),
          height: 4,
          backgroundColor: School.highlight || Colors.lightBlue,
        }}
      />
    </View>
  )
}

export default ProgressBar
