import React, { } from 'react'
import NavTabBar from '../components/navTabBar'
import BlockBox from '../components/blocks/BlockBox'
import EmailBlock from '../components/blocks/EmailBlock'
import { Screens } from '../App'

export interface IHomeProps {
  mobile: boolean,
}
function Help({ mobile, }: IHomeProps) {
  return (
    <>
      <NavTabBar mobile={mobile} screen={Screens.Help} />

      <BlockBox>
        <EmailBlock order={0} mobile={mobile} />
      </BlockBox>
    </>
  )
}

export default Help
