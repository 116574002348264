import React from 'react'
import { View, DimensionValue, ScrollView, } from 'react-native'
import { useSelector } from 'react-redux'
import { IStores } from '../../state/store'
import Text from '../text'
import { email } from '../../utils/constants'

interface IInfoBlockProps {
  order: number,
  mobile: boolean,
}
function TosBlock({ order, mobile, }: IInfoBlockProps) {
  const { Colors, Fonts, Spacing, } = useSelector((state: IStores) => mobile ? state.systemStore.Mobile : state.systemStore.Desktop)

  return (
    <>
      <View style={{position: 'absolute', top: `${order}00%` as DimensionValue, height: '100%', width: '100%', paddingHorizontal: Spacing.padding, backgroundColor: 'rgba(23,25,30, 1)',}}>
        <ScrollView showsVerticalScrollIndicator={false} style={{width: '100%',}}>
          <Text style={{textAlign: 'center', fontWeight: Fonts.welterWeight, fontSize: Fonts.sm, color: Colors.white,}}>{`
\n\n\n<b>Terms of Service</b>\n

Introduction\n
Welcome to Streetpass. These Terms of Service ("Terms") govern your access to and use of our social media platform, website, mobile application, and related services (collectively, the "Services"), operated by Streetpass under Kite App Inc. ("Streetpass," "we," "us," or "our"). By accessing or using the Services, you agree to be bound by these Terms, which constitute a legally binding agreement between you and Streetpass.\n

Eligibility\n
To use the Services, you must be at least 18 years old. By accessing or using the Services, you represent and warrant that you meet these eligibility requirements.\n

User Accounts\n
To access certain features of the Services, you must register for an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify Streetpass immediately of any unauthorized access or use of your account.\n

User Content\n
By submitting, posting, or displaying content on or through the Services, you grant Streetpass a worldwide, non-exclusive, royalty-free license to use, copy, reproduce, process, adapt, modify, publish, transmit, display, and distribute such content in any and all media or distribution methods. You represent and warrant that you have all rights, power, and authority necessary to grant the rights granted herein to any content submitted.\n

Conduct and Content Restrictions\n
You agree not to use the Services to:\n
a. Upload, post, or transmit content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, invasive of another's privacy, hateful, or racially, ethnically, or otherwise objectionable;
b. Harm minors in any way;
c. Impersonate any person or entity or falsely state or otherwise misrepresent your affiliation with a person or entity;
d. Upload, post, or transmit unsolicited or unauthorized advertising, promotional materials, "junk mail," "spam," "chain letters," "pyramid schemes," or any other form of solicitation;
e. Upload, post, or transmit any material that contains software viruses or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications equipment;
f. Interfere with or disrupt the Services or servers or networks connected to the Services, or disobey any requirements, procedures, policies, or regulations of networks connected to the Services;
g. Intentionally or unintentionally violate any applicable local, state, national, or international law;
h. Collect or store personal data about other users without their express permission.\n

Termination\n
Streetpass reserves the right to suspend or terminate your access to the Services at any time, with or without cause or notice, including for violations of these Terms.\n

Disclaimer of Warranties and Limitation of Liability\n
The Services are provided on an "as is" and "as available" basis. Streetpass disclaims all warranties of any kind, whether express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement. Streetpass does not warrant that the Services will be uninterrupted, timely, secure, or error-free. In no event shall Streetpass be liable for any indirect, incidental, special, consequential, or exemplary damages arising out of or in connection with your use of the Services.\n

Indemnification\n
You agree to indemnify and hold Streetpass and its subsidiaries, affiliates, officers, agents, employees, partners, and licensors harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your use of the Services, your violation of these Terms, or your infringement, or the infringement by any other user of your account, of any intellectual property or other right of any person or entity.\n

Governing Law and Dispute Resolution\n
These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which Streetpass is incorporated, without regard to its conflict of law provisions. Any disputes arising from or related to these Terms or your use of the Services shall be resolved through final and binding arbitration in accordance with the rules of an arbitration organization mutually agreed upon by the parties.\n

Changes to Terms\n
Streetpass reserves the right to modify or update these Terms at any time, with or without prior notice. Your continued use of the Services after any such changes constitutes your acceptance of the new Terms. It is your responsibility to review these Terms periodically for updates or changes.\n

Miscellaneous\n
These Terms, together with the Privacy Policy and any other legal notices published by Streetpass on the Services, constitute the entire agreement between you and Streetpass concerning your use of the Services. If any provision of these Terms is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms, which shall remain in full force and effect.\n

Streetpass Description of Service\n
Streetpass enables users to discover and connect with other users based on geographic proximity. This feature is intended to enhance user interaction and networking opportunities within the Streetpass community.\n

Streetpass Eligibility\n
Streetpass is available only to users who are 18 years of age or older. By enabling and using Streetpass, you affirm that you are at least 18 years old.\n

Streetpass User Consent and Data Usage\n
By opting into Streetpass, you consent to the collection and use of your location data to facilitate user discovery based on geographic proximity. You understand and agree that your location data will be used to enable the functionality of Streetpass.\n

Streetpass User Discretion and Privacy\n
You have full control over your participation in Streetpass. You can opt-out or disable your location at any time through the app settings. While using Streetpass, you are encouraged to exercise discretion and caution with respect to your personal safety and privacy.\n

Streetpass Responsibility and Conduct\n
Users are solely responsible for their interactions with others via Streetpass. Streetpass is not responsible for the conduct of any user. Users should report any inappropriate behavior or concerns to Streetpass support.\n

\n<b>Paid Services</b>\n

In-App Purchases\n
In-app purchases are made for perishable services within the Streetpass app.\n
All in-app purchases are non-refundable, except through the app store's refund process.\n
Any outstanding balances from in-app purchases will be forfeited 90 days after account deletion.\n

Subscriptions\n
Subscriptions are offered on a month-to-month basis.\n
Users may cancel their subscription at any time without providing a reason.\n
Failure to make a payment will result in automatic cancellation of a subscription.\n

Account Deletion & Re-activation\n
If a user deletes their account and later creates a new one:\n
a. In-app purchases can be recovered within 90 days of the original account deletion.\n
b. Subscriptions can be recovered if the user re-signs up before the expiration of the original subscription period.\n

General Terms\n
All payments are processed through the app store or our authorized payment processors.\n
Prices for in-app purchases and subscriptions are subject to change. Any price changes will be communicated to users in advance.\n
Users are responsible for all charges incurred under their account.\n
Streetpass reserves the right to suspend or terminate services for accounts with outstanding balances infringing on these Terms of Service without reimbursement.\n

\n<b>Privacy Policy</b>\n

Introduction\n
Streetpass is committed to protecting your privacy. This Privacy Policy outlines the types of information we collect from you when you use the Services, how we use that information, and the choices you have regarding our collection and use of your information.\n

Information We Collect\n
We may collect the following types of information from you:\n
a. Personal Information: We collect personal information, such as your name, email address, phone number, and location, when you register for an account or otherwise voluntarily provide it to us.
b. User Content: We collect the images, videos, and other content you submit, post, or display on or through the Services.
c. Usage Information: We automatically collect information about your interactions with the Services, including the pages you visit, the features you use, and the time and duration of your use.\n

How We Use Your Information\n
We may use your information for the following purposes:\n
a. To provide, maintain, and improve the Services;
b. To communicate with you about the Services, including sending updates, promotions, and marketing materials;
c. To personalize your experience on the Services, such as by providing content and features tailored to your interests;
d. To monitor and analyze the usage and effectiveness of the Services;
e. To detect, prevent, or address technical or security issues;
f. To enforce our Terms of Service and other policies;
g. To comply with legal obligations and respond to legal requests.\n

Sharing Your Information\n
We may share your information with third parties in the following circumstances:\n
a. With your consent;
b. With our service providers who perform services on our behalf, such as hosting providers or analytics providers;
c. In response to a legal request or as required by law;
d. In connection with a merger, acquisition, or sale of assets;
e. To protect the rights, property, or safety of Streetpass, our users, or others.\n

Data Security\n
We implement reasonable measures to protect the security of your personal information. However, no method of transmission or storage is completely secure, and we cannot guarantee the absolute security of your information.\n

Your Choices\n
You have the right to access, update, or delete your personal information at any time by logging into your account and modifying your profile settings. You may also opt out of receiving promotional communications from us by following the unsubscribe instructions included in those communications.\n

Third-Party Links and Services\n
The Services may contain links to third-party websites or services. We are not responsible for the privacy practices or the content of such websites or services. We encourage you to review the privacy policies of these third-party websites or services.\n

Children's Privacy\n
The Services are not intended for use by anyone under the age of 18. We do not knowingly collect personal information from anyone under the age of 18. If we become aware that a child under 18 has provided us with personal information, we will take steps to delete such information from our systems.\n

International Users\n
If you are using the Services from outside the jurisdiction in which Streetpass is incorporated, please be aware that your information may be transferred to, stored, and processed in that jurisdiction. By using the Services, you consent to the transfer of your information to the jurisdiction in which Streetpass is incorporated and the use and disclosure of your information in accordance with this Privacy Policy.\n

Streetpass Collection of Location Data\n
Streetpass requires the collection and processing of real-time geographic location information from your device. This data is essential to provide the service of connecting you with other nearby users.\n

Streetpass Use of Location Data\n
Location data collected through Streetpass is used exclusively for the purpose of enabling user discovery and connection based on geographic proximity. This data is not used for any other purpose without your explicit consent.\n

Streetpass Sharing of Location Data\n
Your location data will be shared with other Streetpass users who you willingly match with and are in close proximity to you. Your exact location will not be disclosed to other users; only approximate distance information will be shared.\n

Streetpass Data Retention and Security\n
Location data collected through the Streetpass feature is retained only as long as necessary to provide the feature's functionality and is subject to Streetpass's data retention policies. We implement appropriate security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information and data stored on our platform.\n

Streetpass User Control and Access\n
You may disable Streetpass at any time through the app settings. Upon disabling, we will cease to collect your location data.\n

\n\nChanges to Privacy Policy\n
Streetpass reserves the right to modify or update this Privacy Policy at any time, with or without prior notice. Your continued use of the Services after any such changes constitutes your acceptance of the new Privacy Policy. It is your responsibility to review this Privacy Policy periodically for updates or changes.\n

Contact Us\n
If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at <b>${email}<b>.\n

Last Updated: ${new Date('2023-11-29').toLocaleDateString('en-US')}
          `.split('<b>').map((segment, index) => {
              if (segment.includes('</b>')) {
                const [boldText, ...rest] = segment.split('</b>')
                return (<Text key={index}><Text style={{fontWeight: 'bold',}}>{boldText}</Text>{rest}</Text>)
              }
              return segment
            })}
          </Text>

          <View style={{height: 128,}} />
        </ScrollView>
      </View>
    </>
  )
}

export default TosBlock
