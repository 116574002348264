import React from 'react'
import { View, DimensionValue, Linking, } from 'react-native'
import { useSelector } from 'react-redux'
import { IStores } from '../../state/store'
import Button from '../button'
import { email } from '../../utils/constants'
import Text from '../text'

interface IEmailBlockProps {
  order: number,
  mobile: boolean,
}
function EmailBlock({ order, mobile, }: IEmailBlockProps) {
  const { Colors, Fonts, Spacing, } = useSelector((state: IStores) => mobile ? state.systemStore.Mobile : state.systemStore.Desktop)

  return (
    <>
      <View style={{position: 'absolute', top: `${order}00%` as DimensionValue, height: '100%', width: '100%', padding: Spacing.padding, backgroundColor: 'rgba(23,25,30, 1)',}}>
        <View style={{flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center',}}>
          <View style={{flex: mobile ? 2 : 1,}}>
            <Button
              title={'Send'}
              onPress={() => Linking.openURL(`mailto:${email}`)}
            />
          </View>
          <View style={{flex: mobile ? 3 : 5, marginLeft: 16,}}>
            <Text style={{fontSize: Fonts.md, fontWeight: Fonts.welterWeight, color: Colors.white,}}>us an email. We'll get back to you ASAP.</Text>
          </View>
        </View>
      </View>
    </>
  )
}

export default EmailBlock
