import React, { useEffect, useState } from 'react'
import { View, DimensionValue, Image, ScrollView, TouchableOpacity, Dimensions, } from 'react-native'
import { IStores } from '../../state/store'
import { useSelector } from 'react-redux'
import Text from '../text'
import { ReactComponent as StreetpassColorIcon, } from '../../assets/images/logo-color.svg'
import { ReactComponent as QuotesIcon, } from '../../assets/icons/quotes.svg'
import BlurBackground from '../blurBackground'
import { headlines, testimonials } from '../../utils/constants'
import ProgressBar from '../animated/ProgressBar'
import { Screens } from '../../App'
import { useNavigate } from 'react-router-dom'

interface IFooterBlockProps {
  order: number,
  mobile: boolean,
}
function FooterBlock({ order, mobile, }: IFooterBlockProps) {
  const { Colors, Fonts, Spacing, } = useSelector((state: IStores) => mobile ? state.systemStore.Mobile : state.systemStore.Desktop)
  const { School, } = useSelector((state: IStores) => state.systemStore)

  const navigate = useNavigate()

  const [headline, setHeadline] = useState<number>(0)
  const [resetProgressBar, setResetProgressBar] = useState<boolean>(false)
  const useHeadlines = School.headlines ? School.headlines : headlines
  useEffect(() => {
    const updateString = () => {
      let nextHeadline = Math.floor(Math.random() * useHeadlines.length)
      while (headline === nextHeadline) nextHeadline = Math.floor(Math.random() * useHeadlines.length)
      setHeadline(nextHeadline)
      setResetProgressBar(!resetProgressBar)
    }
    const intervalId = setInterval(updateString, 7000)
    return () => clearInterval(intervalId)
  }, [headline, resetProgressBar, useHeadlines.length])

  return (
    <>
      <View style={{position: 'absolute', top: `${order}00%` as DimensionValue, height: '100%', width: '100%', backgroundColor: 'rgba(23,25,30, 1)',}}>

        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center',}}>
          <View style={{position: 'absolute', width: '100%', height: '0%', justifyContent: 'center', alignItems: 'center', marginTop: 8, paddingHorizontal: Spacing.padding,}}>
            <Text style={{fontSize: Fonts.xl, fontWeight: Fonts.middleWeight, color: School.highlight || Colors.lightBlue, textAlign: 'center',}}>{useHeadlines[headline]}</Text>
          </View>
        </View>

        <View style={{width: '100%', paddingHorizontal: 40,}}>
          <ProgressBar duration={7000} reset={resetProgressBar} />
        </View>

        <View style={{flex: mobile ? 1 : 2, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 32,}}>
          <ScrollView
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            style={{width: '100%', height: '100%',}}
            contentContainerStyle={{flexDirection: 'row', alignItems: 'center',}}
          >
            <View style={{width: 32,}} />

            {Object.keys(School?.testimonials || testimonials).map((key: string) => {
              const testimonial = School?.testimonials?.[key] || testimonials[key]
              return (
                <View style={{height: '100%', maxWidth: Dimensions.get('window').width / (mobile ? 1.5 : 2.6), marginHorizontal: 8, borderRadius: 16, borderColor: Colors.white, borderWidth: 1, padding: Spacing.paddingMd,}}>
                  <Text style={{fontSize: Fonts.lg, fontWeight: Fonts.middleWeight, color: Colors.white,}}>
                    <QuotesIcon style={{width: Fonts.xl, height: Fonts.xl,}} /> {key}
                  </Text>
                  <Text style={{fontSize: Fonts.md, fontWeight: Fonts.middleWeight, color: Colors.white, marginTop: 16,}}>
                    {testimonial}
                  </Text>
                </View>
              )
            })}

            <View style={{width: 32,}} />
          </ScrollView>
        </View>

        <View style={{flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 32,}}>
          <StreetpassColorIcon style={{width: Fonts.xxxl, height: Fonts.xxxl,}} />

          {School.logo &&
            <>
              <Text style={{color: Colors.white, fontSize: Fonts.xl, marginRight: mobile ? 5 : 10,}}>𝑿</Text>

              <View style={{
                aspectRatio: 1/1, borderRadius: Fonts.xxxxl, justifyContent: 'center', overflow: 'hidden',
                width: Fonts.xxl, height: Fonts.xxl, backgroundColor: School.highlight, 
              }}>
                <Image
                  source={School.logo}
                  style={{width: '100%', height: '100%',}}
                  resizeMode={'contain'}
                />
              </View>
            </>
          }
        </View>

        {/* <View style={{flex: 5, flexDirection: 'row', justifyContent: 'center', alignItems: 'center',}}>
          <View style={{
            aspectRatio: 1/1, borderRadius: Fonts.xxxxl, justifyContent: 'center', alignItems: 'center', marginBottom: 4,
            width: Fonts.xxxxl, height: Fonts.xxxxl, backgroundColor: School.tone, 
          }}>
            <StreetpassColorIcon style={{width: '90%', height: '90%',}} />
          </View>

          {School.logo &&
            <>
              <View style={{width: 16,}}/>
              <View style={{
                aspectRatio: 1/1, borderRadius: Fonts.xxxxl, justifyContent: 'center', bottom: 2, overflow: 'hidden',
                width: Fonts.xxxxl, height: Fonts.xxxxl, backgroundColor: School.highlight, 
              }}>
                <Image
                  source={School.logo}
                  style={{width: '100%', height: '100%',}}
                  resizeMode={'contain'}
                />
              </View>
            </>
          }
        </View> */}

        <View style={{position: 'absolute', width: '100%', justifyContent: 'center', alignItems: 'center', bottom: 8,}}>
          <TouchableOpacity onPress={() => navigate(Screens.Tos)} style={{marginBottom: 4,}}><Text style={{color: Colors.lightBlue,}}>Terms of Service & Privacy Policy</Text></TouchableOpacity>
          <Text style={{fontSize: 12, fontWeight: Fonts.featherWeight, color: Colors.white,}}>
            © {new Date().getFullYear()} Streetpass | Kite App Inc | All rights reserved
          </Text>
        </View>
      </View>
    </>
  )
}

export default FooterBlock
