import React, { useEffect, useRef, useState } from 'react'
import { View, TouchableOpacity, Image, Linking, ImageSourcePropType, } from 'react-native'
import { useNavigate } from 'react-router-dom'
import { Screens } from '../../App'
import { useSelector } from 'react-redux'
import { IStores } from '../../state/store'
import { appStoreListing, } from '../../utils/constants'
import Text from '../text'
import { sendUtmMarketing, } from '../../utils/services'
import { ReactComponent as StreetpassIcon, } from '../../assets/images/logo-color.svg'
import { ReactComponent as MenuIcon, } from '../../assets/icons/menu.svg'

export interface INavTabChild {
  title?: string,
  image?: ImageSourcePropType,
  style?: any,
  highlight?: boolean,
  disabled?: boolean,
  onPress?: () => void,
}

export interface INavTabButton {
  children?: { [key: string]: INavTabChild, },
  title?: string,
  image?: ImageSourcePropType,
  style?: any,
  stack?: boolean,
  highlight?: boolean,
  disabled?: boolean,
  hide?: boolean,
  onPress?: () => void,
}

export interface NavTabButtons {
  [key: string]: INavTabButton,
}

export interface INavTabBarProps {
  mobile: boolean,
  screen: string,
}
function NavTabBar({ mobile, screen, }: INavTabBarProps) {
  const { Colors, Fonts, Spacing, } = useSelector((state: IStores) => mobile ? state.systemStore.Mobile : state.systemStore.Desktop)
  const { School, } = useSelector((state: IStores) => state.systemStore)

  const navigate = useNavigate()

  const [referer, setReferer] = useState<string | null>(null)
  const [dropdown, setDropdown] = useState<string | null>(mobile ? 'trigram' : null)
  const [subDropdown, setSubDropdown] = useState<string | null>(null)
  const navRef = useRef<any>(null)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const ref = params.get('ref')
    if (ref) setReferer(ref)
  
    function collapseMenu(event?: MouseEvent) {
      if ((navRef.current && !navRef.current.contains(event?.target as Node)) || !event) {
        setDropdown(null)
        setSubDropdown(null)
      }
    }

    document.addEventListener('mousedown', collapseMenu)
    return () => {
      document.removeEventListener('mousedown', collapseMenu)
    }
  }, [navRef])

  const NavTabButtons: NavTabButtons = {
    'icon': {
      title: 'Home',
      // image: require('../../assets/icons/icon-blue.png'),
      stack: false,
      hide: screen === Screens.Home,
      onPress: () => navigate(Screens.Home),
    },
    'iPhone': {
      title: 'Download for iPhone',
      // image: require('../../assets/icons/icon-app.png'),
      style: { borderRadius: 8, },
      highlight: true,
      onPress: async () => {
        if (referer) await sendUtmMarketing(referer)
        Linking.openURL(appStoreListing)
      },
    },
    // 'Android': {
    //   title: 'Android (coming soon)',
    //   // image: require('../../assets/icons/icon-app.png'),
    //   style: { borderRadius: 32, },
    //   hide: screen !== Screens.Home,
    //   disabled: true,
    // },
    '+': {
      title: ((dropdown === '+') || (mobile && subDropdown === '+')) ? 'Less' : 'More',
      children: {
        'About': {
          disabled: screen === Screens.About,
          onPress: () => navigate(Screens.About),
        },
        'Help': {
          disabled: screen === Screens.Help,
          onPress: () => navigate(Screens.Help),
        },
        'Terms of Service & Privacy Policy': {
          disabled: screen === Screens.Tos,
          onPress: () => navigate(Screens.Tos),
        },
      },
    },
  }

  return (
    <>
      <View style={{position: 'absolute', zIndex: 1, width: '100%', bottom: 32, alignItems: 'center', justifyContent: 'center', paddingHorizontal: 64,}}>
        <View
          ref={navRef}
          style={{
            flexDirection: 'row', width: '100%', padding: 16, paddingHorizontal: Spacing.padding, borderRadius: 24,
            justifyContent: 'space-between', alignItems: 'center', backgroundColor: Colors.white, shadowRadius: 8, shadowOpacity: 0.1,
          }}
        >
          {!mobile ? Object.keys(NavTabButtons).map((key: string, index: number) => {
            const item = NavTabButtons[key]
            if (item.hide) return null
            return (
              <View key={index} style={{flex: 1, justifyContent: 'center', alignItems: 'center',}}>
                {item.image ?
                  <TouchableOpacity
                    onPress={() => item.onPress && item.onPress()}
                    activeOpacity={item.disabled ? 0.3 : undefined}
                    style={{
                      flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                      opacity: item.disabled ? 0.3 : undefined,
                    }}
                  >
                    <Image
                      source={item.image}
                      style={{width: 32, height: 32, ...item.style}}
                    />
                    {item.title && <Text style={{fontSize: Fonts.lg, fontWeight: Fonts.welterWeight, marginLeft: 8, color: School.tone || undefined,}}>{item.title}</Text>}
                  </TouchableOpacity>
                : item.title &&
                  <TouchableOpacity
                    onPress={() => {
                      if (item.children) {
                        if (dropdown === key) setDropdown(null)
                        else setDropdown(key)
                      } else item.onPress && item.onPress()
                    }}
                    activeOpacity={item.disabled ? 0.3 : undefined}
                    style={{
                      flexDirection: 'row', opacity: item.disabled ? 0.3 : undefined,
                      backgroundColor: item.highlight ? (School.highlight || Colors.lightBlue) : undefined, paddingHorizontal: 16, paddingVertical: 4, borderRadius: 16,
                    }}
                  >
                    <Text
                      style={{fontSize: Fonts.lg, fontWeight: Fonts.welterWeight, color: item.highlight ? Colors.white : School.tone || undefined,}}
                      props={{numberOfLines: 1,}}
                    >{item.title}</Text>
                    {item.children && <Text style={{fontSize: Fonts.lg, transform: dropdown === key ? 'rotateX(180deg)' : undefined, color: School.tone || undefined,}}>{' \u2303'}</Text>}
                  </TouchableOpacity>
                }
                {dropdown === key && item.children &&
                  <View
                    style={{
                      position: 'absolute', width: 280, bottom: 64, right: -64, padding: 16, paddingHorizontal: 32, borderRadius: 24,
                      backgroundColor: Colors.white, shadowRadius: 8, shadowOpacity: 0.1,
                    }}
                  >
                    {Object.keys(item.children).map((child: string, index: number) => {
                      const item = NavTabButtons[key].children?.[child]
                      return (
                        <TouchableOpacity
                          key={index}
                          onPress={item?.onPress}
                          activeOpacity={item?.disabled ? 0.3 : undefined}
                          style={{width: '100%', marginVertical: 8, opacity: item?.disabled ? 0.3 : undefined,}}
                        >
                          <Text style={{fontSize: Fonts.lg, fontWeight: Fonts.welterWeight, color: School.tone || undefined,}}>{child}</Text>
                        </TouchableOpacity>
                      )
                    })}
                  </View>
                }
              </View>
            )
          }): <>
            <TouchableOpacity
              disabled={screen === Screens.Home}
              onPress={() => navigate(Screens.Home)}
              activeOpacity={screen === Screens.Home ? 0.3 : undefined}
              style={{paddingHorizontal: 8, opacity: screen === Screens.Home  ? 0.3 : undefined,}}
            >
              <StreetpassIcon style={{width: 32, height: 32, fill: School.tone || Colors.lightBlue,backgroundColor: 'rgba(23,25,30, 1)', borderRadius: 12,}} />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                if (dropdown === 'trigram') setDropdown(null)
                else setDropdown('trigram')
              }}
              style={{paddingHorizontal: 8,}}
            >
              {/* <Text style={{fontSize: Fonts.xxl, color: dropdown === 'trigram' ? Colors.lighterBlue : Colors.lightBlue,}}>{' \u2630'}</Text> */}
              <MenuIcon style={{width: 32, height: 32, opacity: dropdown === 'trigram' ? 0.5 : 1, fill: School.tone || Colors.lightBlue,}} />
            </TouchableOpacity>
            {dropdown === 'trigram' &&
              <View style={{
                position: 'absolute', width: '100%', bottom: 72, right: 0, padding: 16, paddingHorizontal: 32, borderRadius: 24,
                backgroundColor: Colors.white, shadowRadius: 8, shadowOpacity: 0.1,
              }}>
                {Object.keys(NavTabButtons).map((key: string, index: number) => {
                  const item = NavTabButtons[key]
                  if (item?.stack === false) return null
                  return (
                    <View key={index}>
                      {item.title &&
                        <View style={{width: '100%', marginVertical: 8, opacity: item.disabled ? 0.3 : undefined,}}>
                          <TouchableOpacity
                            onPress={() => {
                              if (item.children) {
                                if (subDropdown === key) setSubDropdown(null)
                                else setSubDropdown(key)
                              } else item.onPress && item.onPress()
                            }}
                            activeOpacity={item.disabled ? 0.3 : undefined}
                            style={{
                              flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                              backgroundColor: item.highlight ? (School.highlight || Colors.lightBlue) : undefined, paddingHorizontal: item.highlight ? 8 : undefined, paddingVertical: item.highlight ? 8 : undefined, borderRadius: 16,
                            }}
                          >
                            <Text style={{
                              fontSize: Fonts.lg, fontWeight: Fonts.middleWeight, color: item.highlight ? Colors.white : School.tone || undefined,}}
                            >{item.title}</Text>
                            {item.image &&
                              <Image
                                source={item.image}
                                style={{width: 32, height: 32, ...item.style}}
                              />
                            }
                            {item.children && <Text style={{fontSize: Fonts.lg, transform: subDropdown !== key ? 'rotateX(180deg)' : undefined, color: School.tone || undefined,}}>{' \u2303'}</Text>}
                          </TouchableOpacity>

                          {subDropdown === key && item.children &&
                            <View style={{marginVertical: 8,}}>
                              {Object.keys(item.children).map((child: string, index: number) => {
                                const item = NavTabButtons[key].children?.[child]
                                return (
                                  <TouchableOpacity
                                    key={index}
                                    onPress={item?.onPress}
                                    activeOpacity={item?.disabled ? 0.3 : undefined}
                                    style={{width: '100%', opacity: item?.disabled ? 0.3 : undefined, paddingVertical: 8,}}
                                  >
                                    <Text style={{fontSize: Fonts.lg, fontWeight: Fonts.welterWeight, color: School.tone || undefined,}}>{child}</Text>
                                  </TouchableOpacity>
                                )
                              })}
                            </View>
                          }
                        </View>
                      }
                    </View>
                  )
                })}
              </View>
            }
          </>}
        </View>
      </View>
    </>
  )
}

export default NavTabBar
