import React, { useRef } from 'react'
import NavTabBar from '../components/navTabBar'
import BlockBox from '../components/blocks/BlockBox'
import { Screens } from '../App'
import InfoBlock from '../components/blocks/InfoBlock'
import AboutBlock from '../components/blocks/AboutBlock'

export interface IAboutProps {
  mobile: boolean,
}
function About({ mobile, }: IAboutProps) {
  const masterRef = useRef<any>(null)

  return (
    <>
      <NavTabBar mobile={mobile} screen={Screens.About} />

      <BlockBox>
        <AboutBlock order={0} mobile={mobile} />
      </BlockBox>
    </>
  )
}

export default About
