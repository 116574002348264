import React, { useRef, } from 'react'
import NavTabBar from '../components/navTabBar'
import BlockBox from '../components/blocks/BlockBox'
import VideoBlock from '../components/blocks/VideoBlock'
import InfoBlock from '../components/blocks/InfoBlock'
import FooterBlock from '../components/blocks/FooterBlock'
import PromoBlock from '../components/blocks/PromoBlock'
import { Screens } from '../App'

export interface IHomeProps {
  mobile: boolean,
}
function Home({ mobile, }: IHomeProps) {
  const masterRef = useRef<any>(null)

  return (
    <>
      {/* <NavTabBar mobile={mobile} screen={Screens.Home} /> */}

      <BlockBox masterRef={masterRef}>
        <VideoBlock order={0} mobile={mobile} masterRef={masterRef} />
        <InfoBlock order={1} mobile={mobile} masterRef={masterRef} />
        <FooterBlock order={2} mobile={mobile} />
        {/* <PromoBlock order={0} mobile={mobile} /> */}
      </BlockBox>
    </>
  )
}

export default Home
